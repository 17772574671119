var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.userExperience.userExperienceImagePath,
            _vm.userExperience.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.userExperience.userExperienceImageIsDefault &&
          _vm.hasPrivilegeDeleteImage},on:{"changeValue":function($event){_vm.userExperience.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-generalJobToken"),"errors":_vm.errors_generalJobToken,"value":_vm.userExperience.generalJobToken,"options":_vm.generalJobTokenOptions,"title":_vm.$t('GeneralJobs.select'),"imgName":'generalJobs.svg'},on:{"changeValue":function($event){_vm.userExperience.generalJobToken = $event;
            _vm.$v.userExperience.generalJobToken.$touch();}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-startDateTime"),"type":"dateTime","value":_vm.userExperience.startDateTime,"title":_vm.$t('UserExperiences.startDateTime'),"language":_vm.language,"maxDate":new Date()},on:{"changeValue":function($event){_vm.userExperience.startDateTime = $event.dateTime}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-endDateTime"),"type":"dateTime","value":_vm.userExperience.endDateTime,"title":_vm.$t('UserExperiences.endDateTime'),"language":_vm.language,"maxDate":new Date()},on:{"changeValue":function($event){_vm.userExperience.endDateTime = $event.dateTime}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.userExperience.fullCode,"title":_vm.$t('UserExperiences.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.userExperience.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userExperiencePlaceNameAr"),"errors":_vm.errors_userExperiencePlaceNameAr,"value":_vm.userExperience.userExperiencePlaceNameAr,"title":_vm.$t('UserExperiences.PlaceNameAr'),"imgName":'address.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperiencePlaceNameAr = $event;
            _vm.$v.userExperience.userExperiencePlaceNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userExperiencePlaceNameEn"),"errors":_vm.errors_userExperiencePlaceNameEn,"value":_vm.userExperience.userExperiencePlaceNameEn,"title":_vm.$t('UserExperiences.PlaceNameEn'),"imgName":'address.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperiencePlaceNameEn = $event;
            _vm.$v.userExperience.userExperiencePlaceNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userExperiencePlaceNameUnd"),"value":_vm.userExperience.userExperiencePlaceNameUnd,"title":_vm.$t('UserExperiences.PlaceNameUnd'),"imgName":'address.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperiencePlaceNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userExperienceDescriptionAr"),"errors":_vm.errors_userExperienceDescriptionAr,"value":_vm.userExperience.userExperienceDescriptionAr,"title":_vm.$t('UserExperiences.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperienceDescriptionAr = $event;
            _vm.$v.userExperience.userExperienceDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userExperienceDescriptionEn"),"errors":_vm.errors_userExperienceDescriptionEn,"value":_vm.userExperience.userExperienceDescriptionEn,"title":_vm.$t('UserExperiences.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperienceDescriptionEn = $event;
            _vm.$v.userExperience.userExperienceDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userExperienceDescriptionUnd"),"value":_vm.userExperience.userExperienceDescriptionUnd,"title":_vm.$t('UserExperiences.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperienceDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-userExperienceNotes"),"value":_vm.userExperience.userExperienceNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.userExperience.userExperienceNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }